<template>
  <div>
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
        <el-breadcrumb-item>工程项目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="personnel_form">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="项目类别：">
          <div class="flex personnel_form_sort">
            <div
              :class="
                index === sortType
                  ? 'personnel_form_sort_item personnel_form_sort_item_on'
                  : 'personnel_form_sort_item'
              "
              v-for="(item, index) in form.projectType"
              :key="index"
              @click="onSelectProjectType(index, item.name)"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="企业注册属地：">
          <div class="flex personnel_form_select">
            <el-select
              class="personnel_form_select_item"
              v-model="form.province"
              placeholder="请选择省级"
              @change="(val) => changeProvinceData(val, 'province')"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="item.adCode"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              class="personnel_form_select_item"
              v-model="form.city"
              placeholder="请选择市级"
              @change="(val) => changeProvinceData(val, 'city')"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="item.adCode"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              class="personnel_form_select_item"
              v-model="form.county"
              placeholder="请选择县级"
              @change="(val) => changeProvinceData(val, 'county')"
            >
              <el-option
                v-for="(item, index) in countyList"
                :key="item.adCode"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="建设单位名称：">
          <div class="flex personnel_form_set">
            <el-input
              class="personnel_form_set_input"
              placeholder="请输入建设单位名称"
              v-model="form.buildcorpname"
              clearable
            ></el-input>
            <el-button
              type="primary"
              class="personnel_form_set_btn"
              @click="getProjectList"
              >立即查询</el-button
            >
            <el-button @click="handleReset()">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <generic-table
        ref="genericTable"
        :get-data="getPageData"
        :columns="displayColumns"
        :page-size="size"
        :hide-pagination="false"
        :headerCellBkcolor="'#F8F8F8'"
        @linkAction="onLinkAction"
      ></generic-table>
    </div>
  </div>
</template>

<script>
import cache from "@/libs/util.cache";
import GenericTable from "@/components/generic-table";
import { urlProjectList, urlColumnList } from "@/api/account";
import { h5PartnerApplyRegion } from "@/api/aptitude";

export default {
  data() {
    return {
      displayColumns: [
        { label: "项目名称", prop: "prjname", minWidth: 25, hasLink: true },
        { label: "项目编号", prop: "prjnum", minWidth: 20 },
        { label: "项目类别", prop: "prjtypenum", minWidth: 20 },
        { label: "建设单位", prop: "buildcorpname", minWidth: 20 },
      ],
      form: {
        prjname: this.$route.query.name,
        buildcorpname: "", // 输入建设单位名称
        province: "", // 省
        city: "", // 市
        county: "", // 区
        prjtypenum: "", // 项目类别
        projectType: [],
      },
      sortType: 0,
      current: 1,
      size: 20,
      provinceList: [],
      cityList: [],
      countyList: [],
    };
  },
  mounted() {
    document.title = "工程项目_邦建通";
    this.getApplyRegion();
    this.getColumnList();
  },
  components: { GenericTable },
  methods: {
    handleReset() {
      if (this.$refs.form) this.$refs.form.resetFields();      
      this.sortType = 0;
      this.form.province = "";
      this.form.city = "";
      this.form.county = "";
      this.form.buildcorpname = "";
      this.form.prjtypenum = "";
      this.form.prjname = "";
    },
    findNodeById(name, nodes) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.name === name) return node;
        if (node.children && node.children.length > 0) {
          const childNode = this.findNodeById(name, node.children);
          if (childNode) return childNode;
        }
      }
      return null;
    },
    extractBuildCorpName() {
      const buildcorpname = this.$route.params.buildcorpname;
      if (buildcorpname) {
        console.log("extractBuildCorpName-->buildcorpname=" + buildcorpname);
        this.form.buildcorpname = buildcorpname;
        this.$route.params.buildcorpname = "";
      }
    },
    changeProvinceData(val, type) {
      // 获取选中的省份节点
      if (type === "province") {
        const provinceNode = this.findNodeById(val, this.provinceList);
        if (provinceNode) {
          // 更新选中的省份数据
          this.form.province = provinceNode.name;
          // 重置选中的城市和区县
          this.form.city = "";
          this.form.county = "";
          // 获取选中的省份节点对应的城市数据
          this.cityList = provinceNode.child;
        }
      } else if (type === "city") {
        const cityNode = this.findNodeById(val, this.cityList);
        if (cityNode) {
          this.form.city = cityNode.name;
          this.county = "";
          this.countyList = cityNode.child;
        }
      }
    },
    async getColumnList() {
      const param = `appid=wxbd94b37dd45c1f91`;
      const res = await urlColumnList(param);
      const { data } = res;
      const id = data.filter((item) => item.name === "工程项目分类")[0].id;
      this.form.projectType = data.filter((item) => item.pid === id) || [];
    },
    async getApplyRegion() {
      const res = await h5PartnerApplyRegion();
      this.provinceList = res.data;
    },
    async getPageData({ size, current }) {
      this.extractBuildCorpName();
      this.size = size;
      this.current = current;
      const param = {
        buildcorpname: this.form.buildcorpname,
        city: this.form.city,
        county: this.form.county,
        prjname: this.form.prjname,
        prjtypenum: this.form.prjtypenum,
        province: this.form.province,
        current: current,
        size: size,
      };
      // 获取数据的 API 请求
      const response = await urlProjectList(param);
      const records = response.data ? response.data.records : [];
      const total = response.data ? response.data.total : 0;
      return {
        data: records,
        total: total,
      };
    },
    async getProjectList() {
      this.current = 1;
      if (this.$refs.genericTable) this.$refs.genericTable.reload(this.current);
    },
    onSelectProjectType(index, itemName) {
      if (this.sortType === index) return;
      this.sortType = index;
      this.form.prjtypenum = index == 0 ? "" : itemName;
    },
    onLinkAction(type, item) {
      cache.setSessionStorage("projectDetails", item);
      this.$router.push({ path: "/project/details" });
    },
  },
};
</script>
<style scoped lang="less">
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 25px;
  .fs-18 {
    font-size: 18px;
  }
}
.personnel_form {
  padding: 40px 20px;
  border: 1px solid #dcdcdc;
  .personnel_form_sort {
    padding: 0 5px;
    .personnel_form_sort_item {
      height: 40px;
      padding: 0 12px;
      font-weight: bold;
      margin: 0 5px;
      cursor: pointer;
    }
    .personnel_form_sort_item_on {
      background-color: #edf4ff;
      border-radius: 4px;
      color: #1890ff;
    }
  }
  .personnel_form_select {
    .personnel_form_select_item {
      flex: 1;
      margin: 0 10px;
    }
  }
  .personnel_form_set {
    .personnel_form_set_input {
      margin: 0 10px;
    }
    .personnel_form_set_btn {
      margin-left: 50px;
    }
  }
}
</style>
<style scoped>
::v-deep .el-button--primary {
  background-color: #007ef2;
  border-color: #007ef2;
}
::v-deep .personnel_form .personnel_form_sort .personnel_form_sort_item_on{
  color: #007ef2;
}
</style>
